body {
  margin: 0;
  padding: 0;
  font-family: "DIN W03 Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  color: #ffffff;
  font-family: "DIN W03 Regular", sans-serif;
}

code {
  font-family: "DIN W03 Regular", sans-serif;
}

.header-section {
  background-size: cover;
  background-position: top;
  background-image: url("/header_image_uma.jpg");
  background-color: #080c10;
  height: 1400px;
}

.section-a {
  background-color: #ffffff;
  height: 850px;
  padding-bottom: 100px;
}

.section-b {
  background-color: #ff8aa2;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.section-f {
  background-color: #ff8aa2;
  height: 700px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.section-c {
  /* border-top: 25px solid #FF8AA2; */
  background-color: #080c10;
  height: 700px;
}

.section-d,
.section-g {
  background-color: #080c10;
  height: 500px;
}

.section-d {
  /* border-bottom: 25px solid #FF8AA2; */
}

.section-g {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.section-e {
  background-color: #ffffff;
  height: 725px;
}

.section-e-padding {
  height: 750px;
}

.section-e .article-section {
  padding-top: 10%;
}

.section-wrapper {
  padding-top: 5rem;
  padding-left: 9rem;
}

.header-name {
  font-size: 5rem;
  color: #ffffff;
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
}

.header-name div {
  margin-bottom: -50px;
}

.header-sub {
  color: rgb(255, 142, 162);
  font-size: 2rem;
  font-family: "DIN W03 Bold", sans-serif;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

#home-animation-video {
  width: 438px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 89px;
  z-index: 1;
  border-radius: 30px;
}

#home-animation-video-2 {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 150px;
  z-index: 1;
  max-height: 540px;
  max-width: 960px;
}

.img-mobile {
  position: relative;
  z-index: 1000;
}

.video-phone {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  z-index: 1000;
}

.video-computer {
  position: relative;
  top: 100px;
  z-index: 1000;
}

.video-computer img {
  height: 600px;
  width: 1080px;
}

.video-1 {
  position: absolute;
  top: 650px;
}

.video-2 {
  z-index: 1000;
}

.quote-text-1 {
  color: #080c10;
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  line-height: 75px;
}

.quote-by-1 {
  color: #ffffff;
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 2rem;
}

.article-title {
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
  font-size: 2rem;
  color: #080c10;
}

.article-title-f {
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
  font-size: 2rem;
  color: #080c10;
}

.article-sub-title {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.85rem;
  color: #989ca0;
}

.article-content {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding-top: 26px;
  color: #585c60;
}

.article-content-f {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding-top: 26px;
  color: #080c10;
}

.article-sub-title-f {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  text-align: center;
  font-size: 1.85rem;
  color: #080c10;
}

.article-section {
  padding-top: 400px;
  padding-left: 10%;
}

.contact-button {
  font-family: "DIN W03 Medium", sans-serif;
  font-weight: 500;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  box-shadow: 0rem 0.25rem 1.75rem 0rem rgba(0, 0, 0, 0.25);
  background-color: #ff8ea2;
  border-radius: 0.25rem;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000000;
  font-size: 1.05rem;
  text-align: center;
  max-width: 200px;
  transition: all 0.5s ease-out;
}

.contact-button:hover {
  cursor: pointer;
  transition: all 0.5s ease-out;
  box-shadow: 0rem 0.25rem 1.75rem 0rem rgba(0, 0, 0, 0.55);
}

.article-title-c {
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
  font-size: 2rem;
  color: #ffffff;
  padding-top: 200px;
}

.article-sub-title-c {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.85rem;
  color: #fd8ba3;
}

.article-content-c {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding-top: 26px;
  color: #989ca0;
}

.article-section-c {
  padding-top: 0px;
  padding-left: 10%;
}

.article-title-d {
  font-family: "DIN W03 Black", sans-serif;
  font-weight: 900;
  font-size: 2rem;
  color: #ffffff;
  padding-top: 100px;
}

.article-sub-title-d {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.85rem;
  color: #585c60;
}

.article-content-d {
  font-family: "DIN W03 Regular", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding-top: 26px;
  color: #989ca0;
}

.article-section-d {
  padding-top: 275px;
  padding-left: 10%;
}

.article-section-f {
  padding-top: 50px;
}

.padding-reset {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media only screen and (max-width: 767px) {
  .header-section {
    height: 800px;
  }

  .section-a {
    height: 900px;
    padding-bottom: 50px;
  }

  .section-b,
  .section-f {
    height: 400px;
  }

  .section-c {
    height: 400px;
  }

  .section-d {
    height: 300px;
  }

  .section-e {
    height: 700px;
  }

  .section-f {
    height: 500px;
  }

  .section-g {
    height: 500px;
    padding-bottom: 115px;
    padding-top: 75px;
  }

  .section-e-padding {
    height: 600px;
  }

  .section-e .article-section {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .article-title-c {
    padding-top: 125px;
  }

  .article-section-c {
    padding-left: 16px;
    padding-right: 16px;
  }

  .article-content-c {
    padding-bottom: 100px;
  }

  .article-section-f {
    padding-top: 0px;
  }

  .video-1 {
    top: 425px;
  }

  .video-3 {
    top: 0px;
  }

  .header-name {
    font-size: 3.25rem;
    line-height: 3.75rem;
    padding-bottom: 16px;
  }

  .header-name div {
    margin-bottom: 0px;
  }

  .header-sub {
    font-size: 1.5rem;
    line-height: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .article-section {
    padding-top: 200px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-wrapper {
    padding-top: 16px;
    padding-left: 16px;
    overflow-x: hidden;
  }

  .mobile-fluid {
    width: 100vw;
  }

  #home-animation-video {
    width: 55vw;
    top: 38px;
    border-radius: 10px;
  }

  .quote-text-1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    padding-bottom: 16px;
  }

  .quote-by-1 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .video-computer {
    top: 16.5vh;
  }

  #home-animation-video-2 {
    top: 21vh;
  }
}

@media only screen and (max-width: 320px) {
  .article-section {
    padding-top: 100px;
  }
}

/* iPhone 6,7,8 */
@media only screen and (min-width: 375px) and (max-width: 667px) {
  #home-animation-video-2 {
    top: 21vh;
  }
}

/* iPhone X */
@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  #home-animation-video-2 {
    top: 20vh;
  }
}

/* iPhone 6,7,8 Plus */
@media only screen and (min-width: 414px) and (max-width: 736px) {
  #home-animation-video-2 {
    top: 21vh;
  }
}

/* @media only screen and (min-width: 1921px) {
  .video-computer img {
    width: 3000px;
  }
  .section-e-padding {
    height: 1100px;
  }
  .video-phone {
    top: 50px;
  }
} */

.learn-more-button {
  margin-top: 50px;
  padding: 8px;
  border-radius: 6px;
  width: 150px;
  text-align: center;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.15);
}

.learn-more-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

.learn-more-text {
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}

.download-now {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9000;
  text-align: center;
  transition: all 0.3s ease-out;
}

.download-now a img {
  transition: all 0.3s ease-out;
}

.ios-store-badge {
  width: 164px;
}
.android-store-badge {
  width: 200px;
}

.videos-dialog {
  margin-top: 2%;
  width: 70%;
  max-width: 70% !important;
  margin: 0 auto;
}

.videos-dialog .modal-content {
  margin-top: 5%;
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  min-height: 500px;
}

.margin-left {
  margin-left: 24px;
}

.umg-logo {
  width: 50%;
  padding-left: 50px;
  border-left: 1px solid #585c60;
}

.umg-logo--container {
  text-align: left;
}

.uma-logo {
  width: 50%;
  padding-right: 50px;
}

.uma-logo--container {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .video-2 {
      visibility: hidden;
      overflow-x: hidden;
  }
  .section-a {
    height: 950px;
  }
  .video-phone {
    left: 47%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .video-2 {
      visibility: visible;
      overflow-x: visible;
  }
  .video-phone {
    left: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .umg-logo--container {
    text-align: center;
  }
  .umg-logo {
    width: 70%;
    padding-left: 0px;
    border-left: 0px;
    padding-top: 50px;
    border-top: 1px solid #585c60;
  }
  .uma-logo {
    width: 65%;
    padding-right: 0px;
    padding-bottom: 40px;
  }
  .uma-logo--container {
    text-align: center;
  }
  .margin-left {
    margin-left: 4%;
  }
  .learn-more-button {
    margin-right: auto;
    margin-top: 40px;
    padding: 4px 6px;
    border-radius: 6px;
    width: 48%;
    text-align: center;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 18px;
  }
  .download-now {
    bottom: 0px;
    right: 0px;
    left: 3%;
  }
  .ios-store-badge {
    width: 44%;
  }
  .android-store-badge {
    width: 55%;
  }
  .videos-dialog {
    width: 100%;
    max-width: 100% !important;
    margin: 0 auto;
  }
  .videos-dialog .modal-content {
    margin-top: 10%;
    min-height: 0px;
  }
  .video-2 {
    display: none;
  }
}

.sizzle-video {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.sizzle-video--iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.modal-backdrop.show {
  opacity: 0.75;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

